export default {
  mounted: (el) => {
    // console.log(el)
    const interactChapterSettings = {
      root: null,
      rootMargin: '0px 0px -100px 0px'
    }
    // let chapterObserver = {}
    const onInterChapter = (entries, chapterObserver) => {
      entries.forEach(entry => {
        if (entry.target.id === 'exampleview') {
          const arrownav = document.getElementById('controls')
          const bubblenav = document.getElementById('examplenav')
          if (entry.isIntersecting) {
            bubblenav.classList.add('isActive')
            arrownav.classList.add('isActive')
          } else {
            bubblenav.classList.remove('isActive')
            arrownav.classList.remove('isActive')
          }
        } else {
          if (entry.isIntersecting) {
            if (entry.target.id === 'developer') {
              chapterObserver.unobserve(entry.target)
            }
            checkChapter(entry.target)
            if (entry.target.id === 'skills') {
              createScrollEvent()
            }
          } else {
            removeInView(entry.target)
            if (entry.target.id === 'skills') {
              destroyScrollEvent()
              // restoreScaledElements()
            }
          }
        }
      })
    }
    if ('IntersectionObserver' in window) {
      const chapterObserver = new IntersectionObserver(onInterChapter, interactChapterSettings)
      chapterObserver.observe(el)
    } else {
      checkChapter()
    }
    function checkChapter (elem) {
      if (elem.classList.contains('inview') === false) {
        elem.classList.add('inview')
      }
    }
    function removeInView (elem) {
      if (elem.classList.contains('inview') === true) {
        elem.classList.remove('inview')
      }
    }
    // skrolling
    let supportsPassive = false
    const elem = window
    let scrollPosLast = 0
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: () => {
          supportsPassive = true
        }
      })
      window.addEventListener('testPassive', null, opts)
      window.removeEventListener('testPassive', null, opts)
    } catch (e) {
      console.log(e)
    }
    function throttle (func, delay = 8000) {
      let timeout = null
      return function (...args) {
        if (!timeout) {
          timeout = setTimeout(() => {
            func.call(this, ...args)
            timeout = null
          }, delay)
        }
      }
    }
    function scrollHandler () {
      throttle(getScrollDirection())
    }
    function setScaleElement (direction) {
      const scaleElements = document.querySelectorAll('li.skillsblock.inview')
      Array.from(scaleElements).map((scaleElement) => {
        (direction === 'up') ? setElement(scaleElement, 'up') : setElement(scaleElement, 'down')
      })
    }
    // function restoreScaledElements () {
    //   const scaleElements = document.querySelectorAll('li.skillsblock')
    //   Array.from(scaleElements).map((scaleElement) => {
    //     scaleElement.style.transform = ''
    //   })
    // }
    function setElement (elem, direction) {
      let newSale = 1
      let oldScale = elem.style.transform || 'scale(0.9)'
      oldScale = oldScale.toString().replace(')', '')
      oldScale = oldScale.replace('scale(', '')
      if (direction === 'up') {
        newSale = +oldScale - 0.001
      } else {
        newSale = +oldScale + 0.001
      }
      if (newSale <= 1 && newSale > 0.7) {
        elem.style.transform = 'scale(' + newSale.toString() + ')'
      }
    }
    function getScrollDirection () {
      const scrollPos = window.pageYOffset || document.documentElement.scrollTop
      if (scrollPos > scrollPosLast) {
        // console.log('down')
        setScaleElement('down')
      } else {
        // console.log('up')
        setScaleElement('up')
      }
      scrollPosLast = scrollPos <= 0 ? 0 : scrollPos
      // console.log(scrollPos)
    }
    function createScrollEvent () {
      elem.addEventListener('scroll', scrollHandler, supportsPassive ? { passive: true } : false)
    }
    function destroyScrollEvent () {
      elem.removeEventListener('scroll', scrollHandler, supportsPassive ? { passive: true } : false)
    }
  },
  methods: {
    name () {

    }
  },
  unmount: () => {

  }
}
