<template>
  <section class="content" v-on:scroll.passive="handleScroll()">
    <DeveloperDisplay name="Eva Over"/>
    <SkillsDisplay></SkillsDisplay>
    <ExamplesDisplay></ExamplesDisplay>
    <ContactDisplay></ContactDisplay>
  </section>
</template>

<script>
// @ is an alias to /src
import DeveloperDisplay from '@/components/DeveloperDisplay.vue'
import SkillsDisplay from '@/components/SkillsDisplay.vue'
import ExamplesDisplay from '@/components/ExamplesDisplay.vue'
import ContactDisplay from '@/components/ContactDisplay.vue'

export default {
  name: 'Home',
  components: {
    DeveloperDisplay,
    SkillsDisplay,
    ExamplesDisplay,
    ContactDisplay
  }
}
</script>
