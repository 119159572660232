<template>
    <section id="developer" class="" v-chaperterobserve>
      <h1>{{name}}</h1>
      <h2 v-html="jobs"></h2>
      <router-link class="getInContact" to="/#contact" title="Kontakt" v-html="buttontext"></router-link>
    </section>
</template>
<script>
export default {
  name: 'DeveloperDisplay',
  props: {
    name: String
  },
  data () {
    return {
      jobs: 'Web-Front&shy;end-Developer<br>sorgsame Programmierung, lesbar und erlebbar',
      buttontext: 'Kontakt'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  #developer {
    width: 100%;
    height: auto;
    position: relative;
    display: block;
    margin-top: calc( -10vh - 50px );
    padding: 20vh 0 0 0;
    box-sizing: border-box;
    overflow: hidden;
  }
  /*--------------------developer--------------------*/
  #developer{
    /*background-image: url(../../images/hintergrund2022.jpg);*/
    /*background-position: 50% -84px;*/
    background-position: 50% -4vw;
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;
    animation-play-state:paused;
  }
  #developer.inview{
    animation-name: move;
    animation-duration: 14s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-play-state: running;
  }
  @keyframes move {
      0% {
          background-position: 50% 0;
          background-size: 100% auto;
      }
      100% {
          background-position: 50% -4vw;
          background-size: 108% auto;
      }
  }
  #developer h1 {
    transform: translateY(50vh);
    transition: transform 1s ease-out 1.2s, opacity 1s ease-out 1.2s;
    opacity: 0;
  }

  #developer.inview h1 {
    transform:translateY(0);
    transition: transform 1s ease-out 1.2s, opacity 1s ease-out 1.2s;
    opacity: 1;
  }
  #developer h2 {
    transform: translateY(50vh);
    transition: transform 0.7s ease-out 1.5s, opacity 0.7s ease-out 1.5s;
    opacity: 0;
  }

  #developer.inview h2 {
    transform:translateY(0);
    transition: transform 0.7s ease-out 1.5s, opacity 0.7s ease-out 1.5s;
    opacity: 1;
  }
  #developer a.getInContact{
    border-radius: 50px;
    background-color: #d2d4bb;
    padding: 1em 3em;
    text-align: center;
    color:#3d3e42;
    display: inline-block;
    position: relative;
    transform: translateY(50vh);
    opacity: 0;
    margin: 6vh auto;
    border: 1px solid #3d3e42;
    transition: transform 0.7s ease-out 1.7s, opacity 0.7s ease-out 1.7s, border 1s ease-out, color 1s ease-out, background-color 1s ease-out;
  }
  #developer.inview a.getInContact:hover,
  #developer a.getInContact:hover{
    color:#d2d4bb;
    text-decoration: none;
    background-color: #3d3e42;
    border: 1px solid #d2d4bb;
    transition: border 1s ease-out, color 1s ease-out, background-color 1s ease-out;
  }
  #developer.inview a.getInContact{
    transform:translateY(0);
    opacity: 1;
    transition: transform 0.7s ease-out 1.7s, opacity 0.7s ease-out 1.7s;

  }
/*--- >= 1380 --------------------------------------------------------------------------------*/
@media screen and (min-width: 1380px)  {
  #developer {
    background-image: url(../assets/images/hintergrund2022.jpg);
    /*background-position: 50% -84px;*/
    background-position: 50% -6vw;
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 128% auto;
    animation-play-state: paused;
    height: 105vh;
  }
  #developer.inview {
    animation-play-state:running;
  }
}
/*--- < 1380 --------------------------------------------------------------------------------*/
@media screen and (max-width: 1379px) and (min-width: 1060px){
  #developer,#developer.inview {
        background-image: url(../assets/images/hintergrund2022_1380.jpg);
        height: 105vh;
    }
}
/*--- < 1060 --------------------------------------------------------------------------------*/
@media screen and (max-width: 1059px) and (min-width: 900px){
  #developer,#developer.inview {
    background-image: url(../assets/images/hintergrund2022_1380.jpg);
    height: 105vh;
  }
}
/*--- < 900 ------------------------------------------
--------------------------------------*/
@media screen and (max-width: 899px)and (min-width: 601px) {
  #developer,#developer.inview {
    background-image: url(../assets/images/hintergrund2022_800.jpg);
    height: 112vh;
  }
}
/*--- <= 600 --------------------------------------------------------------------------------*/
@media screen and (max-width: 600px) and (min-width: 461px) {
  #developer,#developer.inview{
    background-image: url(../assets/images/hintergrund2022_400.jpg);
    /*background-position: 50% -84px;*/
    background-position: 50% -6vw;
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;
    animation-play-state:paused;
    animation: none;
    height: 112vh;
  }
}
/*--- <= 460 --------------------------------------------------------------------------------*/
@media screen and (max-width: 460px) {
  #developer,#developer.inview{
    background-image: url(../assets/images/hintergrund2022_400.jpg);
    /*background-position: 50% -84px;*/
    background-position: 50% 0;
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;
    animation-play-state:paused;
    animation: none;
    padding-left: 4%;
    padding-right: 4%;
    height: 112vh;
  }
}
/*--- <= 370 --------------------------------------------------------------------------------*/
@media screen and (max-width: 370px) {
  #developer,#developer.inview{
    background-image: url(../assets/images/hintergrund2022_400.jpg);
    /*background-position: 50% -84px;*/
    background-position: 50% 0;
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;
    padding-left: 4%;
    padding-right: 4%;
    height: 112vh;
  }
}
/*--- <= 500 Height--------------------------------------------------------------------------------*/
@media (max-height: 500px){
  #developer,#developer.inview{
    height: 114vh;
  }
}
</style>
