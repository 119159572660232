<template>
  <section id="contact" class="" v-chaperterobserve>
    <div class="bg">
      <div class="mail holder"></div>
      <h3>{{ headline }}</h3>
      <div class="addressholder">
        <div class="vcard">
          <h4>Eva Over</h4>
          <span v-for="entry in entries" v-bind:key="entry"><span v-html="entry"></span></span>
        </div>
        <figure class="eva" v-lazyload>
           <image-tag :slide="slide"></image-tag>
        </figure>
      </div>

    </div></section>
</template>

<script>
import address from '../assets/json/address.json'
export default {
  name: 'ContactDisplay',
  data () {
    return {
      headline: 'Kontakt',
      slide: {
        id: 12,
        checked: false,
        class: 'eva',
        type: 'image',
        src: './assets/images/EvaOver.webp',
        alt: 'Eva Over',
        description: 'Eva Over'
      }
    }
  },
  computed: {
    entries () {
      return address.addrs.map((item) => {
        return item
      })
    }
  }
}
</script>

<style scoped>
  #contact {
    width: 100%;
    height: 105vh;
    padding: 6vh 0 16vh 0;
    position: relative;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    /*margin-bottom: -100px;*/
  }
  /*--------------------Contact--------------------*/
  #contact {
    /* background-position: 0 bottom ;
    background-attachment: fixed;
    background-size: 100% auto; */
    overflow-y: hidden;
    transition: all 8s ease-in-out;

    background-color:hsla(228,3%,24%,1);
    background-image:
    radial-gradient(at 40% 20%, hsla(214,1%,44%,1) 0px, transparent 50%),
    radial-gradient(at 80% 0%, hsla(0,0%,15%,1) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(240,27%,12%,1) 0px, transparent 50%),
    radial-gradient(at 80% 50%, hsla(219,5%,23%,1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(210,21%,25%,1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(0,24%,12%,1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(217,17%,26%,1) 0px, transparent 50%);
  }

  #contact .addressholder{
    display: grid;
    grid-template-columns: repeat(2, 50% );
    grid-template-rows: auto;
    text-align: left;
    width: 95vw;
    /* padding: 2em 0; */
    grid-gap: 1em;
    margin: 0 auto;
    text-align: right;
    box-shadow: 1vw 1vw 24px rgba(45, 46, 49, 0.5);
    /* background-color: rgba(58, 59, 63, 0.5); */
    background-color: rgba(131, 122, 105, 0.3);
  }
  #contact .addressholder h4{
    font: 4.1rem/1.2 rabioheadregular;
    margin-bottom: 1rem;
    color:#FFFFFF;
  }
   #contact .addressholder figure.eva {
    margin: 0;
    display: block;
    text-align: left;
    justify-self: left;
    max-height: 50vh;
    transform: scale(1) rotate(0deg);
    transition: transform 1.7s ease-in-out;
    cursor: url('../assets/images/kontakt.png'), auto;
  }
  #contact .addressholder figure.eva:hover {
    transform: scale(0.99)  rotate(0.2deg);
    transition: transform 1.7s ease-in-out;
  }

  #contact .addressholder figure.eva picture img {
    width: 100%;
    max-width: 360px;
    display: block;
    height: auto;
    max-height: 100%;
    box-shadow: 1vw 1vw 24px #2c2d33;
  }
  #contact .addressholder .vcard {
    width: auto;
    text-align: left;
    padding: 5rem 0;
    justify-self: center;
    align-self: baseline;
    /* background-color: hsla(0,0%,15%,.5); */
  }
  #contact .addressholder .vcard span {
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 1.2;
    white-space: nowrap;
    padding-left: 4px;
  }

  #contact h3 {
    transform: translateY(100vh);
    opacity: 0;
    transition: transform 2s ease-out, opacity 2s ease-out;
  }

  #contact.inview h3 {
      transform: translateY(0);
      opacity: 1;
      transition: transform 2s ease-out, opacity 2s ease-out;
  }
  #contact .addressholder {
    transform: translateY(100vh);
    opacity: 0;
    transition: transform 1.7s ease-out 0.3s, opacity 1.7s ease-out 0.3s;
  }

  #contact.inview .addressholder {
      transform: translateY(0);
      opacity: 1;
      transition: transform 1.7s ease-out 0.3s, opacity 1.7s ease-out 0.3s;
  }
  #contact .addressholder figure.eva{
      opacity: 0;
      transition: opacity 2s ease-in-out;
  }
  #contact.inview .addressholder figure.eva{
      opacity: 1;
      transition: opacity 2s ease-in-out 2s;
  }
  .holder.mail {
    background: url(../assets/images/kontakt.png) no-repeat center;
    background-size: 99px 98px;
  }
/*--- < 1380 --------------------------------------------------------------------------------*/
@media screen and (max-width: 1379px) and (min-width: 1060px){
    /* #contact {
        background: transparent url(../assets/images/hintergrund_1380.jpg) 0 bottom fixed;
        background-size: 100% auto;
    }

    #contact.inview {
        background: transparent url(../assets/images/hintergrund_1380.jpg) 100% bottom fixed;
        background-size: 100% auto;
    } */
}
/*--- < 1060 --------------------------------------------------------------------------------*/
@media screen and (max-width: 1059px) and (min-width: 900px){

}
/*--- < 900 --------------------------------------------------------------------------------*/
@media screen and (max-width: 899px)and (min-width: 601px) {
  /* #contact {
    background: transparent url(../assets/images/hintergrund_800.jpg) 0 bottom fixed;
    background-size: 124% auto;
  }
  #contact.inview {
    background: transparent url(../assets/images/hintergrund_800.jpg) 100% bottom fixed;
    background-size: 124% auto;
  } */
  #contact .addressholder figure.eva img {
    max-width: 230px;
  }
}
/*--- <= 600 --------------------------------------------------------------------------------*/
@media screen and (max-width: 600px) and (min-width: 460px) {
  #contact, #contact.inview{
    transition: none;
  }
  #contact .addressholder .vcard a,
  #contact .addressholder .vcard a:hover {
    text-decoration: none;
    font-size: 120%;
    line-height: 100%;
    white-space: nowrap;
    transition: unset;
  }
  #contact .addressholder{
    padding: 2em;
  }
  #contact .addressholder figure.eva img {
    max-width: 230px;
  }
   #contact .addressholder h4{
     font: 3.1rem/1.2 rabioheadregular;
    margin-bottom: 1rem;
   }

}
/*--- <= 460 --------------------------------------------------------------------------------*/
@media screen and (max-width: 460px) {
  /* #contact,#contact.inview {
    background-image: url(../assets/images/hintergrund_400.jpg) ;
    background-position: 0 bottom ;
    background-attachment: fixed;
    background-size: 100% auto;
  } */
   #contact .addressholder{
    display: block;
    text-align: left;
    width: 95vw;
    margin: 0 auto;
    text-align: center;
    padding: 2em;
  }
   #contact .addressholder h4{
     font: 3.1rem/1.2 rabioheadregular;
    margin-bottom: 1rem;
   }
  #contact .addressholder figure.eva{
   margin: 0 auto;
    max-width: 38vw;
  }
  #contact .addressholder .vcard {
    margin: 0;
    width: auto;
    padding: 1em;
    text-align: center;
  }

  /* #contact div.bg .vcard a,
  #contact div.bg .vcard a:hover {
    text-decoration: none;
    font-size: 120%;
    line-height: 100%;
    white-space: nowrap;
    transition: unset;
  } */
}
/*--- <= 370 --------------------------------------------------------------------------------*/
@media screen and (max-width: 370px) {
  /* #contact, #contact.inview  {
    background-image: url(../assets/images/hintergrund_400.jpg) ;
    background-position: 80% bottom ;
    background-attachment: fixed;
    background-size: 100% auto;
  } */
  #contact .addressholder figure.eva img{
    margin: 0 auto;
    max-width: 32vw;
  }
}
/*--- >= 1380 --------------------------------------------------------------------------------*/
@media screen and (min-width: 1380px)  {
  /* #contact {
    background-image: url(../assets/images/hintergrund.jpg) ;
    background-position: 0 bottom ;
    background-attachment: fixed;
    background-size: 100% auto;
    overflow-y: hidden;
    transition: all 8s ease-in-out;
  } */
}
/*--- <= 500 Height--------------------------------------------------------------------------------*/
@media (max-height: 620px) and (min-width: 300px){
  #contact,#contact.inview{
    height: auto;
  }
  #contact .addressholder figure.eva {
    display: none;
  }
}
/*--- >= 792 --------------------------------------------------------------------------------*/
@media screen and (max-height: 792px) {
  #contact,#contact.inview {
    padding:5vh 0 24vh 0;
  }
}
</style>
