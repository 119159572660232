<template>
    <picture>
      <source :datasrcset="imgXLG" :media="mediaXLG">
      <source :datasrcset="imgLG" :media="mediaLG">
      <source :datasrcset="imgMD" :media="mediaMD">
      <source :datasrcset="imgSM" :media="mediaSM">
        <img :datasrc="imgSrc"  :alt="altImgTag">
    </picture>
</template>

<script>
export default {
  name: 'ImageTag',
  props: {
    slide: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      imgSMPath: null,
      imgMDPath: null,
      imgLGPath: null,
      imgXLGPath: null
    }
  },
  methods: {
    image () {
      const lastIndexOfDot = this.slide.src.lastIndexOf('.')
      return this.slide.src.substring(0, lastIndexOfDot)
    },
    imageType () {
      const lastIndexOfDot = this.slide.src.lastIndexOf('.')
      return this.slide.src.substring(lastIndexOfDot)
    },
    returnImgSMPath () {
      this.imgSMPath = this.image() + 'SM' + this.imageType()
      return this.imgSMPath
    },
    returnImgMDPath () {
      this.imgMDPath = this.image() + 'MD' + this.imageType()
      return this.imgMDPath
    },
    returnImgLGPath () {
      this.imgLGPath = this.image() + 'LG' + this.imageType()
      return this.imgLGPath
    },
    returnImgXLGPath () {
      this.imgXLGPath = this.image() + 'XLG' + this.imageType()
      return this.imgXLGPath
    }
  },
  computed: {
    altImgTag () {
      return this.slide.alt
    },
    imgSrc () {
      return this.returnImgMDPath()
    },
    imgSM () {
      return this.returnImgMDPath() + ' 1x, ' + this.returnImgMDPath() + ' 2x'
    },
    imgMD () {
      return this.returnImgMDPath() + ' 1x, ' + this.returnImgMDPath() + ' 2x'
    },
    imgLG () {
      return this.returnImgMDPath() + ' 1x, ' + this.returnImgLGPath() + ' 2x'
    },
    imgXLG () {
      return this.returnImgLGPath() + ' 1x, ' + this.returnImgXLGPath() + ' 2x'
    },
    mediaSM () {
      return '(max-width:' + (this.$SM - 1) + 'px)'
    },
    mediaMD () {
      return '(min-width:' + this.$SM + 'px) and (max-width:' + this.$MD + 'px)'
    },
    mediaLG () {
      return '(min-width:' + (this.$LG) + 'px) and (max-width:' + (this.$XLG - 1) + 'px)'
    },
    mediaXLG () {
      return '(min-width:' + this.$XLG + 'px)'
    }
  }
}
</script>

<style scoped>

</style>
