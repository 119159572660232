<template>
  <section id="example" class="" v-chaperterobserve>
    <div class="bg">
      <div class="work holder" id="projects"></div>
      <h3 id="example-headline">Erfahrungen</h3>
      <div id="slider">
        <input
          v-for="(slide, index) in slides"
          type="radio"
          :key="index"
          :id="'slide' + slide.id"
          :value="slide.id"
          v-model="slideChecked"
          class="set"
          name="slider"
        />
        <ul class="example" id="examplenav">
          <li
            v-for="(slide, index) in slides"
            :key="index"
            v-on:click="showSlide(index),scrollUp(),pauseVideo()"
          >
            <figure><div :class="'muster ' + slide.class"></div></figure>
          </li>
        </ul>
        <div id="controls">
          <label
            v-for="(slide, index) in slides"
            :for="'slide' + slide.id"
            :key="index"
            class="sliderbutton"
            @click="pauseVideo(),closeAllText(),addButtonMore(),scrollUp()"
          ></label>
        </div>
        <ul class="view" id="exampleview" v-chaperterobserve>
          <li v-for="slide in slides" :key="slide" :class="slide.class">
            <figure v-lazyload>
              <video
                v-if="slide.type === 'video'"
                autobuffer=""
                controls=""
                preload="metadata"
                :id="slide.class"
              >
                <source :src="slide.src" type="video/mp4" />
              </video>
              <image-tag v-else v-bind:slide="slide"></image-tag>
              <figcaption v-html="slide.description"></figcaption>
              <div class="buttonMore" v-on:click="moreOrLess($event)">mehr...</div>
            </figure>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ExamplesDisplay',
  props: {
    name: String
  },
  data () {
    return {
      slides: [
        {
          id: 1,
          checked: true,
          class: 'voonbox',
          type: 'image',
          src: './assets/images/apple-devices_voonbox.png',
          alt: 'Webapplikation',
          description:
            '<p><strong>Bereich:</strong> IT-Software</p><p>Die vorhandene Unternehmenssoftware konnte nicht mehr aktualisiert werden. Ein Relaunch war nötig. Erste Konzepte und Layouts waren vorhanden und von den Chefs freigegeben. Dieses Layout habe ich dann weiterentwickelt und finalisiert.</p><p> Im Laufe der Programmierung wuchs auch das Projekt. Wir ermöglichten immer neue Feature, da wir ein modulares Konzept verfolgten. Modulübergreifende Wiederverwendbarkeit von Features war für uns ein Grundprinzip.</p><p> Schließlich konnten wir unserer Agentur ein multi-modulares Online-Kollaborations-Tools zur strategischen Kampagnenplanung unserer Kunden und deren Dienstleister zur Verfügung stellen. Dazu gehört eine Verwaltung bestehend aus Benutzern mit detaillierter Rechtevergabe, Firmen, Benutzergruppen, Profile sowie eine Plugin-Verwaltung. Darüber hinaus gab es Komponenten für übergreifenden Dateiaustausch, Wikis, Rechtstext und Schreibweisen Bibliothek. Das Highlight des Systems war eine Verwaltung von Bannerkampagnen (digital Ads) mit der Abbildung der internen und externen Freigabeprozesse zur Qualitätssicherung.</p><p><strong>Meine Aufgabe</strong>: Layoutweiterentwicklung, Frontend-Konzeption und Programmierung einer Webapplikation mit Backendentwickler.</p>'
        },
        {
          id: 2,
          checked: false,
          class: 'k2',
          type: 'image',
          src: './assets/images/apple-devices_k2.png',
          alt: 'Webseite Raumausstatter k2',
          description:
            '<p><strong>Bereich:</strong> Handwerk</p><p>Der „Raumausstatter Phillipp Kaszuba“ benötigte eine neue Website, die er selber pflegen kann. Die Lösung bot das CMS „concrete5“. Die Designerin entwarf eine architektonische Aufteilung, um die Arbeit in Räumen wieder zu spiegeln. Herausforderung des Entwurfes waren eine Vielzahl von Bildern, die über das CMS gepflegt werden sollten. Auf großen Bildschirmen sortierten sich die Bilder in eine Grundrissanmutung, mobil bildeten sie einen Bilderslider.</p><p>Ich programmierte einen Slider, der sich erst initialisierte, nachdem alle seine Bilder geladen waren und der sich je nach Bildschirmbreite auflöste oder neu initialisierte, damit ein responsives Verhalten sichergestellt war. Dazu entwickelte ich ein Template-System, mit der man verschiedene Bildkompositionen auswählen könnte.</p><p><strong>Meine Aufgabe</strong>: Frontend-Programmierung, entwickeln eines Templatesystems für Bildkompositionen mit Text und einen selbst initialisierenden Slider für Bilder mit Backend-Entwickler und Designer.</p>'
        },
        {
          id: 3,
          checked: false,
          class: 'spacestar',
          type: 'image',
          src: './assets/images/apple-devices_spacestar.png',
          alt: 'Space Star sucht Modestar',
          description:
            '<p><strong>Bereich:</strong> Automotive</p><p>Der "Space Star von Mitsubishi" sollte für junge Frauen attraktiv beworben werden. Die Idee war die Modebewusste Zielgruppe über "Germany next Topmodel" auf den City-Flitzer aufmerksam zu machen. Hierzu wurde ein Gewinnspiel eingerichtet. Man konnte Rolf Scheider dazu engagieren. Mitsubishi ermöglichte jungen Frauen einen Blick mit ihm in die Modelwelt. Sie konnten über diese Webseite am Gewinnspiel teilnehmen. Über die Zeitung couch warb man in verschiedenen Kanälen und verlinkte auf dieses Gewinnspiel. Ergänzend dazu machten diverse Ads auf das Gewinnspiel aufmerksam.</p><p><strong>Meine Aufgabe</strong>: Programmierung eines responsiven One-Pager mit Integration eines Onlineformulares zur Anmeldung an ein Gewinnspiel und entprechende HTML5 Werbebanner.</p>'
        },
        {
          id: 4,
          checked: false,
          class: 'mmd',
          type: 'video',
          src: require('@/assets/videos/100JahreDNA.mp4'),
          alt: '100 Jahre Mitsubishi',
          description:
            '<p><strong>Bereich:</strong> Automotive</p><p>Mitsubishi wurde 100 Jahre. Man wollte das Motto  „100 Jahre. Gute Fahrt.“ emotional einführen. Die Idee war eine vollständige Übersicht aller bisher gebauten Mitsubishi-Modelle in einer ungewöhnlichen, zukunftsgerichteten aber neugierig machenden Weise, zum Erkunden einladend. Der Plan war Mitsubishi-Fahrer mit ein zu beziehen, ihre kleine Geschichte mit auf zu nehmen und sie damit emotional an die Seite/Marke zu binden.</p><p>Die Designer entwickelten eine einmalige Darstellung der Modelle auf einer pulsierenden, leuchtenden Helix. Sie verband alle Elemente zu einem Zeitstrahl, auf der man in die Vergangenheit oder in die Zukunft reisen konnte.</p><p>Was schließlich anwenderfreundlich funktionierte, war viel Testen und Tüfteln mit Blick auf Performance und individuelles Browserverhalten. Die Design-Idee war neu und so noch nicht umgesetzt. Ich musste viele native Browserfunktionen abschalten und durch Skript gesteuertes Verhalten ersetzen. Die meisten Animationen habe ich über CSS3 steuern können, damit auch schwächere Rechner ein fast flüssiges Verhalten zeigen. Wir erfanden ein skalierbares System im CMS, in dem Elemente nach Belieben ergänzt oder entfernt werden konnten, sodass die Seite auch vom Kunden gepflegt werden konnte. Die eingepflegten Themen sortieren sich durch mein Skript automatisch in einer Sinus-Kurve in den richtigen Zeitabschnitt.</p></p><p><strong>Meine Aufgabe</strong>: Frontendentwicklung eines responsiven One-Pager mit Parallaxeffekt im CMS "Concrete5". Die pflegbaren Inhalte positionieren sich skriptgesteuert nach Jahr in einer Sinuskurve als Elemente auf der DNA.</p>'
        },
        {
          id: 5,
          checked: false,
          class: 'judowillich',
          type: 'image',
          src: './assets/images/apple-devices-judowillich.png',
          alt: 'Judo-Sportverein Willich',
          description:
            '<p><strong>Bereich:</strong> Sport & Wellness</p><p>Aufgabe war die interessante Repräsentation des Judo Vereines in Willich und der einzelnen Abteilungen mit pflegbaren Informationen zu Veranstaltungen und Kursen einschließlich Gebührenübersicht. Meine Idee war eine Art Infobrett. Die Startseite sollte viele kleine Teaser zu den Infobereichen zeigen, sodass diese schnell aufzufinden sind. Jede Abteilung hatte ihr individuelles Startbild. Die Seite entstand über das Open Source Framework "ProcessWire" und der Integration eines Blogs. Später würde noch die  Facebook-Like-Box integriert, um Inhalte nicht doppelt pflegen zu müssen.</p><p><strong>Meine Aufgabe</strong>: Entwurf und Umsetzung eines adaptiven Layouts mit dem CMS ""ProcessWire"", inklusive Einbau einer Blog-Funktion.</p>'
        },
        {
          id: 6,
          checked: false,
          class: 'kwo',
          type: 'image',
          src: './assets/images/monitor_kwo.png',
          alt: 'Webshop KWO',
          description:
            '<p><strong>Bereich:</strong> Schmuck & Uhren</p><p>Aufgabe war ein freier Online-Shop parallel zum ebay-Auftritt. Es sollte dieselbe Zielgruppe angesprochen werden und der Shop sollte in verschiedenen Sprachen - auch Kunden aus dem Ausland - dienen. Die Artikel mussten vom Inhaber selbst gepflegt werden können. Durch den Einsatz von Open Source Software konnte das Vorhaben kostengünstig realisiert werden. Zum Einsatz kam die freie Shopsoftware modified eCommerce. Als vertrauensbildende Maßnahme wurden die positiven Bewertungen über eine ebay-Schnittstelle im Shop integriert.</p><p><strong>Meine Aufgabe</strong>: Entwurf und Einbau eines statischen Templates mit der "modified eCommerce Shopsoftware" und integrierter ebay-Kundenrezension.</p>'
        },
        {
          id: 7,
          checked: false,
          class: 'bjk',
          type: 'image',
          src: './assets/images/monitor_BJK.png',
          alt: 'Webshop BJK',
          description:
            '<p><strong>Bereich:</strong> Merchandising</p><p>Aufgabe war das Einrichten einer freien Shopsoftware und Umsetzung des Templates nach fertigem Layout. Zur Auswahl kam wieder die "modified eCommerce Shopsoftware". Eine kompatible Warenwirtschaft sollte den neuen Shop ergänzen. "JTL-Wawi" ist eine anwenderfreundliche Open-Source Warenwirtschaft zur Unterstützung der gängigsten Geschäftsprozesse wie Artikel-, Kunden-, Lieferanten- und Rechnungsverwaltung mit klarer Ausrichtung auf den Versandhandel. Später wurde noch ein freies mobiles Template integriert.</p><p><strong>Meine Aufgabe</strong>: Umsetzung eines vorhandenen Entwurfes mit der "modified eCommerce Shopsoftware", Anbindung an die Warenwirt&shy;schaft und Integration eines freien Handy-Templates.</p>'
        },
        {
          id: 8,
          checked: false,
          class: 'statistik',
          type: 'image',
          src: './assets/images/monitor_MMD_verkaufszahlen.png',
          alt: 'Analyse Webapp',
          description:
            '<p><strong>Bereich:</strong> Statistik</p><p>Um sich beim Verkauf von Fahrzeugen einen besseren Einblick in Abhängigkeiten zu machen war eine visuelle Ansicht der Verkaufszahlen je Stadt, Einwohnerzahl, Autohaus und Modell gefragt. Hier entschied ich mich, die "Google Maps API" zu verwenden. Verschiedene freie Datenbanken lieferten die geografischen Daten zu Postleitzahlen, Einwohnerzahl Städte und Kreise. Die Verkaufszahlen der Modelle ließen sich über Zulassungszahlen simulieren.</p><p><strong>Meine Aufgabe</strong>: Test-Programmierung mit "AngularJS" visualisierte Verkaufszahlen (fiktiv) in Abhängigkeit von Modell und geografischer Lage unter Einbindung von "Google Maps API".</p>'
        },
        {
          id: 9,
          checked: false,
          class: 'voon',
          type: 'image',
          src: './assets/images/apple-devices_voon.png',
          alt: 'Webseite VOON Werbeagentur',
          description:
            '<p><strong>Bereich:</strong> Werbung & Marketing</p><p>Das regelmäßige Face-Lifting der vorhandenen Webseite stand an. Das war die Gelegenheit das Frontend und das Backend der Seite auf den technisch neusten Stand zu heben. Es galt nicht nur das Layout zu verbessern, sondern auch Usability und Performance im Blick zu behalten.</p><p><strong>Meine Aufgabe</strong>: Frontendentwicklung im "Concrete5.8" im Team mit Designer und Backend-Entwickler.</p>'
        },
        {
          id: 10,
          checked: false,
          class: 'fenerium',
          type: 'image',
          src: './assets/images/monitor_fenerbahce.png',
          alt: 'Webshop Fenerbahce Deutschland',
          description:
            '<p><strong>Bereich:</strong> Merchandising</p><p>Aufgabe war das Einrichten einer freien Shopsoftware und Umsetzung des Templates nach fertigem Layout. Zur Auswahl kam wieder die "modified eCommerce Shopsoftware". Eine kompatible Warenwirtschaft sollte den neuen Shop ergänzen. "JTL-Wawi" ist eine anwenderfreundliche Open-Source Warenwirtschaft zur Unterstützung der gängigsten Geschäftsprozesse, wie Artikel-, Kunden-, Lieferanten- und Rechnungsverwaltung mit klarer Ausrichtung auf den Versandhandel. Später wurde noch ein freies mobiles Template integriert.</p><p><strong>Meine Aufgabe</strong>: Umsetzung eines vorhandenen statischen Entwurfes mit der "modified eCommerce Shopsoftware", Anbindung an die Warenwirt&shy;schaft und Integration eines freien Handytemplates.</p>'
        },
        {
          id: 11,
          checked: false,
          class: 'santafe',
          type: 'video',
          src: require('@/assets/videos/santafe.mp4'),
          alt: '',
          description:
            '<p><strong>Bereich:</strong> Automotive</p><p>Das Modell "Santa Fe" von "Hundai" hatte ein Facelifting bekommen. Über eine Microsite sollten die Neuerungen dem Kunden attraktiv vorgestellt werden. Das vorhandene Bildmaterial sollte in einer aufregenden Zusammenstellung Verwendung finden. Der Designer entschied sich zur musikalischen Verstärkung der dramaturgisch kraftvollen Bilder. Jede Seite bekam seine eigene Musik und einen Spezialeffekt. Die Navigation selbst, stellte eine Art Amplitude der Musik dar, die sich mit der Musik bewegte. Alle Seiten bestanden aus vollflächigen Bildern. Effekte wie Tooltips, Wolken die über den Himmel zogen, Glow der über das Bild fuhr, Farbwechsel der Modelle per Mouseover, Slider mit Thumbnails mussten über das Frontendend über Dom Scripting realisiert werden.</p><p><strong>Meine Aufgabe</strong>: Frontendentwicklung im "Zend-Framework" im Team mit Designer und Backend-Entwickler.</p>'
        },
        {
          id: 12,
          checked: false,
          class: 'wasser',
          type: 'image',
          src: './assets/images/apple-devices_lauffuerwasser.png',
          alt: 'Onlinespiel für WorldVision',
          description:
            '<p><strong>Bereich:</strong> Charity</p><p>In Entwicklungsländer müssen Kinder oft mehr als sechs Kilometer für Wasser laufen. "WorldVision" startete eine Kampagne "Global 6 K bewegt", um Spendengelder für Brunnenbau zu sammeln. Sie organisierten Spendenläufe in Deutschland. Ein spielerischer Lauf dieser Aktion sollte auch auf die Webseite, um Menschen über die Situation der Kinder zu informieren. Als Quiz sollte auf die Gefahren der Kinder beim Wasserholen aufmerksam gemacht werden.</p> <p>Wir entwickelten ein online Würfelspiel mit Fragen als virtuellen Lauf durch die Wüste zum Wasserbrunnen. Gefahren dabei waren dreckiges Wasser oder wilde Tiere wie Löwen, Hyänen und Schlangen. Die Hindernisse sollten im Spiel immer wieder auftauchen, als sogenannte Hindernisfelder und die Quizfragen aufrufen. Diese ließ ich bei der Programmierung zufällig aussuchen. Auch die gewürfelte Zahl erzeugte ich über eine Random-Funktion. Die Bewegung des Spielers erstellte ich durch eine CSS-Animation der Beine.</p><p><strong>Meine Aufgabe</strong>: Programmierung eines Online-Quizspieles im Team mit Designern</p>'
        }
      ],
      slideChecked: 1,
      supportsPassive: false,
      xDown: null,
      yDown: null
    }
  },
  methods: {
    scrollUp () {
      const element = document.getElementById('example')
      const exampleTop = element.offsetTop + 40
      // window.setTimeout(() => {
      //   window.scrollTo(0, exampleTop)
      // }, 1000)
      window.scrollTo(0, exampleTop)
    },
    pauseVideo () {
      const playingVideos = document.querySelectorAll('video')
      Array.from(playingVideos).map(playingVideo => {
        playingVideo.pause()
      })
    },
    showSlide (index) {
      document.getElementById('slide' + (index + 1)).checked = true
    },
    getCheckedSlide () {
      return document
        .querySelector("input[name='slider']:checked")
        .getAttribute('id')
    },
    forward () {
      const current = this.getCheckedSlide()
      if (
        document.querySelector('#' + current).nextElementSibling != null &&
        typeof document.querySelector('#' + current).nextElementSibling !==
          'undefined'
      ) {
        document.querySelector('#' + current).nextElementSibling.checked = true
      }
      this.pauseVideo()
      this.closeAllText()
      this.addButtonMore()
    },
    backward () {
      const current = this.getCheckedSlide()
      if (
        document.querySelector('#' + current).previousElementSibling != null &&
        typeof document.querySelector('#' + current).previousElementSibling !==
          'undefined'
      ) {
        document.querySelector(
          '#' + current
        ).previousElementSibling.checked = true
      }
      this.pauseVideo()
      this.closeAllText()
      this.addButtonMore()
    },
    getTouches (evt) {
      return evt.touches || evt.originalEvent.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      if (!this.xDown || !this.yDown) {
        return
      }
      const xUp = evt.touches[0].clientX
      const yUp = evt.touches[0].clientY
      const xDiff = this.xDown - xUp
      const yDiff = this.yDown - yUp
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          /* left swipe */
          this.forward()
        } else {
          /* right swipe */
          this.backward()
        }
        window.setTimeout(() => {
          this.scrollUp()
        }, 1000)
      } else {
        if (yDiff > 0) {
          /* up swipe */
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      this.xDown = null
      this.yDown = null
    },
    moreOrLess (event) {
      const toRead = event.target.previousElementSibling
      const moreButton = event.target
      if (toRead.classList.contains('more')) {
        toRead.classList.remove('more')
        moreButton.innerText = 'mehr...'
        this.scrollUp()
      } else {
        toRead.classList.add('more')
        moreButton.innerText = 'weniger'
        this.scrollUp()
      }
    },
    closeAllText () {
      const allArticle = document.getElementById('example').querySelectorAll('figcaption')
      Array.from(allArticle).map(article => {
        article.classList.remove('more')
      })
    },
    addButtonMore () {
      const allButtons = document.getElementById('example').querySelectorAll('.buttonMore')
      Array.from(allButtons).map(singleButton => {
        singleButton.innerText = 'mehr...'
      })
    }
  },
  computed: {},
  mounted () {
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: () => {
          this.supportsPassive = true
        }
      })
      window.addEventListener('testPassive', null, opts)
      window.removeEventListener('testPassive', null, opts)
    } catch (e) {
      console.log(e)
    }
    document
      .querySelector('ul.view')
      .addEventListener(
        'touchstart',
        this.handleTouchStart,
        this.supportsPassive ? { passive: true } : false
      )
    document
      .querySelector('ul.view')
      .addEventListener(
        'touchmove',
        this.handleTouchMove,
        this.supportsPassive ? { passive: true } : false
      )
  }
}
</script>

<style scoped>
#example {
  width: 100%;
  height: auto;
  padding: 6vh 0 16vh 0;
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
}
/*--------------------example--------------------*/
#example {
  /*background-image: url(../../images/hintergrund.jpg);*/
  background-position: 50% -1800px;
  background-attachment: fixed;
  background-size: 100% auto;
  width: 100%;
  display: block;
}
#project {
  display: block;
  width: 100%;
  height: 1px;
}
 .holder.work {
    background: url(../assets/images/arbeiten.png) no-repeat center;
    background-size: 99px 98px;
  }
#example div#slider {
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
  position: relative;
  /*left: 0;*/
}
#example h3 {
  margin-bottom: 20px;
  transform: translateY(50vh);
  transition: transform 2s ease-out,  opacity 2s ease-out;
  opacity: 0;
}
#example.inview h3 {
  padding-top: 0;
  margin-bottom: 20px;
  transform: translateY(0);
  opacity: 1;
  transition: transform 2s ease-out, opacity 2s ease-out;
}

input.set {
  display: none;
}
#slide1:checked ~ ul.example {
  transform: translateY(-100px);
  transition: transform 1s ease-out;
}
#slide2:checked ~ ul.example {
  transform: translateY(-200px);
  transition: transform 1s ease-out;
}
#slide3:checked ~ ul.example {
  transform: translateY(-300px);
  transition: transform 1s ease-out;
}
#slide4:checked ~ ul.example {
  transform: translateY(-400px);
  transition: transform 1s ease-out;
}
#slide5:checked ~ ul.example {
  transform: translateY(-500px);
  transition: transform 1s ease-out;
}
#slide6:checked ~ ul.example {
  transform: translateY(-600px);
  transition: transform 1s ease-out;
}
#slide7:checked ~ ul.example {
  transform: translateY(-700px);
  transition: transform 1s ease-out;
}
#slide8:checked ~ ul.example {
  transform: translateY(-800px);
  transition: transform 1s ease-out;
}
#slide9:checked ~ ul.example {
  transform: translateY(-900px);
  transition: transform 1s ease-out;
}
#slide10:checked ~ ul.example {
  transform: translateY(-1000px);
  transition: transform 1s ease-out;
}
#slide11:checked ~ ul.example {
 transform: translateY(-1100px);
 transition: transform 1s ease-out;
}
#slide12:checked ~ ul.example {
  transform: translateY(-1200px);
  transition: transform 1s ease-out;
}
#controls {
  height: auto;
  opacity: 0;
}
#controls label {
  position: fixed;
  z-index: 200;
  width: 60px;
  height: 60px;
  top: 50%;
  margin-top: -30px;
  display: none;
  text-decoration: none;
  text-align: center;
  /* border: 3px solid transparent;
  border-radius: 10px; */
  box-sizing: content-box;
  color: #d2d4bb;
}
#controls.isActive{
 opacity: 1;
}

#slider #controls label:hover {
  text-decoration: none;
  border-color: #d2d4bb;
}
#slider #controls label:before {
  /* font-family: 'iconset', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 76px;
  line-height: 1; */
  /* content: '\e801'; */
  content: '';
  /* text-align: center; */
  position: absolute;
  background: transparent url(../assets/images/arrow_neu.svg) center center no-repeat;
  background-size: 100%;
  width: 60px;
  height: 60px;
  left: 0;
  top: 0;
  transition:transform 0.3s ease-out;

}
#slider #controls label:hover:before {
  transform: translateX(-5px);
  transition:transform 0.3s ease-out;
}
#slide1:checked ~ #controls label:nth-child(2),
#slide2:checked ~ #controls label:nth-child(3),
#slide3:checked ~ #controls label:nth-child(4),
#slide4:checked ~ #controls label:nth-child(5),
#slide5:checked ~ #controls label:nth-child(6),
#slide6:checked ~ #controls label:nth-child(7),
#slide7:checked ~ #controls label:nth-child(8),
#slide8:checked ~ #controls label:nth-child(9),
#slide9:checked ~ #controls label:nth-child(10),
#slide10:checked ~ #controls label:nth-child(11),
#slide11:checked ~ #controls label:nth-child(12)
/* #slide12:checked ~ #controls label:nth-child(1)  */
{
  right: 140px;
  display: block;
  transform: rotate(180deg);
}
#slide2:checked ~ #controls label:first-child,
#slide3:checked ~ #controls label:nth-child(2),
#slide4:checked ~ #controls label:nth-child(3),
#slide5:checked ~ #controls label:nth-child(4),
#slide6:checked ~ #controls label:nth-child(5),
#slide7:checked ~ #controls label:nth-child(6),
#slide8:checked ~ #controls label:nth-child(7),
#slide9:checked ~ #controls label:nth-child(8),
#slide10:checked ~ #controls label:nth-child(9),
#slide11:checked ~ #controls label:nth-child(10),
#slide12:checked ~ #controls label:nth-child(11)
/* #slide1:checked ~ #controls label:nth-child(12)  */
{
  right: 204px;
  display: block;
}
#example ul.example {
  height: 171.6%;
  display: block;
 position: fixed;
  top: calc(50% + 50px);
  right: 0;
  width: 120px;
  margin: 0;
  padding: 0;
  z-index: 300;
  opacity: 0;
  transform: translateY(0);
  transition: transform 1s ease-out, opacity 2s ease-in-out;
}
#example ul.example.isActive{
  opacity: 1;
  transition: transform 1s ease-out, opacity 2s ease-in-out;
}
#example ul.example li {
  height: 100px;
  display: block;
  width: 100px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transform: scale(1.0);
  transition: transform 0.3s ease-out;
}
#example ul.example li:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease-out;
}
#example ul.example li figure {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0;
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
 #example ul.view li figure .buttonMore {
    display: none;
    margin-top: 54px;
  }
#example ul.example div {
  width: 100px;
  margin: 0;
  display: block;
  height: 100px;
  opacity: 0.8;
  transform: scale(0.5);
  /* border-radius: 50%; */
  border-radius: 2px;
  border: 8px solid #414347;
  background: url(../assets/images/musterseiten.jpg) no-repeat center 0;
  background-size: 106% auto;
  box-shadow: 10px 10px 15px rgba(24, 25, 26, 0.22);
  transition: all 1s ease-in-out;
  box-sizing: border-box;
}

#example ul.view {
  width: 1200%;
  text-align: left;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(12, 8.33%);
  /* grid-gap: 1%; */
  position: relative;
  margin: 2vh 0;
  padding: 0 0 1em 0;
  height: auto;
  transition: margin-left 1s ease-in-out;
  box-sizing: border-box;
  overflow-y: hidden;
}
#example ul.view li {
  display: inline-block;
  position: relative;
  height: auto;
  margin: 0;
  padding: 5vh 0;
  text-align: left;
  box-sizing: border-box;
}
#example ul.example div.voonbox {
  background-position: center 100%;
}
#example ul.example div.mmd {
  background-position: center 90.9%;
}
#example ul.example div.spacestar {
  background-position: center 72.9%;
}
#example ul.example div.k2 {
  background-position: center  63.5%;
}
#example ul.example div.judowillich {
  background-position: center 27.5%;
}
#example ul.example div.kwo {
  background-position: center 36%;
}
#example ul.example div.bjk {
  background-position: center 46%;
}
#example ul.example div.voon {
  background-position: center 0;
}
#example ul.example div.statistik {
  background-position: center 81.4%;
}
#example ul.example div.fenerium {
  background-position: center 10%;
}
#example ul.example div.santafe {
  background-position: center 17.9%;
}
#example ul.example div.wasser {
  background-position: center 54.2%;
}
/* #example ul.view li.voonbox,
#example ul.view li.k2,
#example ul.view li.spacestar,
#example ul.view li.mmd,
#example ul.view li.voon,
#example ul.view li.judowillich,
#example ul.view li.wasser,
#example ul.view li.kwo,
#example ul.view li.fenerium,
#example ul.view li.bjk,
#example ul.view li.statistik,
#example ul.view li.santafe {
  background: transparent url('../assets/images/bg_5c5c5c.svg') no-repeat center;
  background-size: cover;
  background-color: #3a3b3f;
} */
#example ul.view li figure {
  width: calc(100% - 280px) ;
  margin: 0 auto;
  height: 100%;
  display: block;
  /* background-color: rgba(58, 59, 63, 0.7);
  box-shadow: 1vw 1vw 24px rgba(47, 48, 51, 0.9); */

}

#example ul.view li.kwo figure picture,
#example ul.view li.bjk figure picture,
#example ul.view li.k2 figure picture,
#example ul.view li.fenerium figure picture,
#example ul.view li.statistik figure picture{
  max-width: 66vw;
  max-height: 67vh;
}
#example ul.view li figure figcaption {
  width: 50%;
  margin: 0 auto;
  display: block;
}

#slide1:checked ~ ul.view {
  margin-left: 0;
}
#slide2:checked ~ ul.view {
  margin-left: -100%;
}
#slide3:checked ~ ul.view {
  margin-left: -200%;
}
#slide4:checked ~ ul.view {
  margin-left: -300%;
}
#slide5:checked ~ ul.view {
  margin-left: -400%;
}
#slide6:checked ~ ul.view {
  margin-left: -500%;
}
#slide7:checked ~ ul.view {
  margin-left: -600%;
}
#slide8:checked ~ ul.view {
  margin-left: -700%;
}
#slide9:checked ~ ul.view {
  margin-left: -800%;
}
#slide10:checked ~ ul.view {
  margin-left: -900%;
}
#slide11:checked ~ ul.view {
  margin-left: -1000%;
}
#slide12:checked ~ ul.view {
  margin-left: -1100%;
}
#slide1:checked ~ ul.example div.voonbox,
#slide2:checked ~ ul.example div.k2,
#slide3:checked ~ ul.example div.spacestar,
#slide4:checked ~ ul.example div.mmd,
#slide5:checked ~ ul.example div.judowillich,
#slide6:checked ~ ul.example div.kwo,
#slide7:checked ~ ul.example div.bjk,
#slide8:checked ~ ul.example div.statistik,
#slide9:checked ~ ul.example div.voon,
#slide10:checked ~ ul.example div.fenerium,
#slide11:checked ~ ul.example div.santafe,
#slide12:checked ~ ul.example div.wasser {
  border: 10px solid #d2d4bb;
  opacity: 1;
  transform: scale(1);
}
/*--- < 1380 --------------------------------------------------------------------------------*/
@media screen and (max-width: 1379px) and (min-width: 1060px) {
  #example,
  #example.inview {
    background-image: url(../assets/images/hintergrund2022_1380.jpg);
    background-position: 50% -560px;
    background-attachment: fixed;
    background-size: 140% auto;
  }
  #example ul.view li figure figcaption {
    margin: 70px auto 0 auto;
    width: 70%;
  }
}
/*--- < 1060 --------------------------------------------------------------------------------*/
@media screen and (max-width: 1059px) and (min-width: 900px) {
  #example,
  #example.inview {
    background-image: url(../assets/images/hintergrund2022_1380.jpg);
    background-position: 50% -560px;
    background-attachment: fixed;
    background-size: 140% auto;
  }
  #slide1:checked ~ #controls label:nth-child(2),
  #slide2:checked ~ #controls label:nth-child(3),
  #slide3:checked ~ #controls label:nth-child(4),
  #slide4:checked ~ #controls label:nth-child(5),
  #slide5:checked ~ #controls label:nth-child(6),
  #slide6:checked ~ #controls label:nth-child(7),
  #slide7:checked ~ #controls label:nth-child(8),
  #slide8:checked ~ #controls label:nth-child(9),
  #slide9:checked ~ #controls label:nth-child(10),
  #slide10:checked ~ #controls label:nth-child(11),
  #slide11:checked ~ #controls label:nth-child(12)
  /* #slide12:checked ~ #controls label:nth-child(1)  */
  {
    right: 2px;
    display: block;
    transform: rotate(180deg);
  }

  #slide2:checked ~ #controls label:first-child,
  #slide3:checked ~ #controls label:nth-child(2),
  #slide4:checked ~ #controls label:nth-child(3),
  #slide5:checked ~ #controls label:nth-child(4),
  #slide6:checked ~ #controls label:nth-child(5),
  #slide7:checked ~ #controls label:nth-child(6),
  #slide8:checked ~ #controls label:nth-child(7),
  #slide9:checked ~ #controls label:nth-child(8),
  #slide10:checked ~ #controls label:nth-child(9),
  #slide11:checked ~ #controls label:nth-child(10),
  #slide12:checked ~ #controls label:nth-child(11)
  /* #slide1:checked ~ #controls label:nth-child(12)  */
  {
    left: 2px;
    display: block;
  }
  #example ul.example {
    display: none;
  }
  #example ul.view li figure {
    width: 96vw;
  }
  #controls label {
    margin-top: -16px;
  }
  #example ul.view li figure figcaption {
    margin: 70px auto 0 auto;
    width: 70%;
  }
}
/*--- < 900 --------------------------------------------------------------------------------*/
@media screen and (max-width: 899px) and (min-width: 601px) {
  #example,
  #example.inview {
    background-image: url(../assets/images/hintergrund2022_800.jpg);
    background-position: 50% -560px;
    background-attachment: fixed;
    background-size: 140% auto;
  }
  #example ul.example {
    display: none;
  }
  #example ul.view li figure {
    width: 92vw;
  }
  #example ul.view li figure figcaption {
    width: 90%;
  }
  #slide1:checked ~ #controls label:nth-child(2),
  #slide2:checked ~ #controls label:nth-child(3),
  #slide3:checked ~ #controls label:nth-child(4),
  #slide4:checked ~ #controls label:nth-child(5),
  #slide5:checked ~ #controls label:nth-child(6),
  #slide6:checked ~ #controls label:nth-child(7),
  #slide7:checked ~ #controls label:nth-child(8),
  #slide8:checked ~ #controls label:nth-child(9),
  #slide9:checked ~ #controls label:nth-child(10),
  #slide10:checked ~ #controls label:nth-child(11),
  #slide11:checked ~ #controls label:nth-child(12)
  /* #slide12:checked ~ #controls label:nth-child(1)  */
  {
    right: 2px;
    display: block;
    transform: rotate(180deg);
  }

  #slide2:checked ~ #controls label:first-child,
  #slide3:checked ~ #controls label:nth-child(2),
  #slide4:checked ~ #controls label:nth-child(3),
  #slide5:checked ~ #controls label:nth-child(4),
  #slide6:checked ~ #controls label:nth-child(5),
  #slide7:checked ~ #controls label:nth-child(6),
  #slide8:checked ~ #controls label:nth-child(7),
  #slide9:checked ~ #controls label:nth-child(8),
  #slide10:checked ~ #controls label:nth-child(9),
  #slide11:checked ~ #controls label:nth-child(10),
  #slide12:checked ~ #controls label:nth-child(11)
  /* #slide1:checked ~ #controls label:nth-child(12)  */
  {
    left: 2px;
    display: block;
  }
   #example ul.view li.kwo figure picture,
  #example ul.view li.bjk figure picture,
  #example ul.view li.k2 figure picture,
  #example ul.view li.fenerium figure picture,
  #example ul.view li.statistik figure picture{
    max-width: unset;
    max-height: unset;
  }
}
/*--- <= 600 --------------------------------------------------------------------------------*/
@media screen and (max-width: 600px) and (min-width: 460px) {
  #example,
  #example.inview {
    background-image: url(../assets/images/hintergrund2022_400.jpg);
    background-position: 50% 40%;
    background-attachment: fixed;
    background-size: 100% auto;
  }
  #example ul.example {
    display: none;
  }
  #example ul.view li figure figcaption {
    margin: 20px auto 0 auto;
    width: 90%;
  }
  #slide1:checked ~ #controls label:nth-child(2),
  #slide2:checked ~ #controls label:nth-child(3),
  #slide3:checked ~ #controls label:nth-child(4),
  #slide4:checked ~ #controls label:nth-child(5),
  #slide5:checked ~ #controls label:nth-child(6),
  #slide6:checked ~ #controls label:nth-child(7),
  #slide7:checked ~ #controls label:nth-child(8),
  #slide8:checked ~ #controls label:nth-child(9),
  #slide9:checked ~ #controls label:nth-child(10),
  #slide10:checked ~ #controls label:nth-child(11),
  #slide11:checked ~ #controls label:nth-child(12)
  /* #slide12:checked ~ #controls label:nth-child(1)  */
  {
    right: 4px;
    display: block;
    transform: rotate(180deg);
  }

  #slide2:checked ~ #controls label:first-child,
  #slide3:checked ~ #controls label:nth-child(2),
  #slide4:checked ~ #controls label:nth-child(3),
  #slide5:checked ~ #controls label:nth-child(4),
  #slide6:checked ~ #controls label:nth-child(5),
  #slide7:checked ~ #controls label:nth-child(6),
  #slide8:checked ~ #controls label:nth-child(7),
  #slide9:checked ~ #controls label:nth-child(8),
  #slide10:checked ~ #controls label:nth-child(9),
  #slide11:checked ~ #controls label:nth-child(10),
  #slide12:checked ~ #controls label:nth-child(11)
  /* #slide1:checked ~ #controls label:nth-child(12)  */
  {
    left: 4px;
    display: block;
  }
   #example ul.view li.kwo figure picture,
  #example ul.view li.bjk figure picture,
  #example ul.view li.k2 figure picture,
  #example ul.view li.fenerium figure picture,
  #example ul.view li.statistik figure picture{
    max-width: unset;
    max-height: unset;
  }
  #example ul.view li figure {
    width: 92vw;
  }
  #example ul.view li figure picture{
    height:45vw;
    display: block;
  }
  #slider #controls label:hover{
    border-color: transparent;
  }
}
/*--- <= 460 --------------------------------------------------------------------------------*/
@media screen and (max-width: 460px) {
  #example,
  #example.inview {
    background-image: url(../assets/images/hintergrund2022_400.jpg);
    background-position: 50% 40%;
    background-attachment: fixed;
    background-size: 100% auto;
  }
  #example ul.example {
    display: none;
  }
  #example ul.view li {
  padding: 4vh 0 10vh 0;
}
  #example ul.view li figure {
    width: 96vw;
  }
   #example ul.view li figure picture{
    height:45vw;
    display: block;
  }
  #example ul.view li figure figcaption{
    margin: 40px auto 20px auto;
    width: 94%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 28vh;
    position: relative;
    padding: 0 0 20px 0;
  }
  #example ul.view li figure figcaption.more{
     text-overflow: unset;
    max-height: unset;
  }
  #example ul.view li figure .buttonMore {
    display: block;
    width: 88px;
    text-align: right;
    height: 22px;
    cursor: pointer;
    position: absolute;
    color:inherit;
    font-size: 1.3rem;
    right: 10%;
  }
#slide1:checked ~ ul.example {
  transform: translateY(-100px);
  transition: transform 1s ease-out 1s;
}
#slide2:checked ~ ul.example {
  transform: translateY(-200px);
  transition: transform 1s ease-out 1s;
}
#slide3:checked ~ ul.example {
  transform: translateY(-300px);
  transition: transform 1s ease-out 1s;
}
#slide4:checked ~ ul.example {
  transform: translateY(-400px);
  transition: transform 1s ease-out 1s;
}
#slide5:checked ~ ul.example {
  transform: translateY(-500px);
  transition: transform 1s ease-out 2s;
}
#slide6:checked ~ ul.example {
  transform: translateY(-600px);
  transition: transform 1s ease-out 2s;
}
#slide7:checked ~ ul.example {
  transform: translateY(-700px);
  transition: transform 1s ease-out 2s;
}
#slide8:checked ~ ul.example {
  transform: translateY(-800px);
  transition: transform 1s ease-out 2s;
}
#slide9:checked ~ ul.example {
  transform: translateY(-900px);
  transition: transform 1s ease-out 2s;
}
#slide10:checked ~ ul.example {
  transform: translateY(-1000px);
  transition: transform 1s ease-out 2s;
}
#slide11:checked ~ ul.example {
 transform: translateY(-1100px);
 transition: transform 1s ease-out 2s;
}
#slide12:checked ~ ul.example {
  transform: translateY(-1200px);
  transition: transform 1s ease-out 2s;
}
  #slide1:checked ~ #controls label:nth-child(2),
  #slide2:checked ~ #controls label:nth-child(3),
  #slide3:checked ~ #controls label:nth-child(4),
  #slide4:checked ~ #controls label:nth-child(5),
  #slide5:checked ~ #controls label:nth-child(6),
  #slide6:checked ~ #controls label:nth-child(7),
  #slide7:checked ~ #controls label:nth-child(8),
  #slide8:checked ~ #controls label:nth-child(9),
  #slide9:checked ~ #controls label:nth-child(10),
  #slide10:checked ~ #controls label:nth-child(11),
  #slide11:checked ~ #controls label:nth-child(12)
  /* #slide12:checked ~ #controls label:nth-child(1)  */
  {
    right: 4px;
    display: block;
    transform: rotate(180deg);
    border: none;
  }

  #slide2:checked ~ #controls label:first-child,
  #slide3:checked ~ #controls label:nth-child(2),
  #slide4:checked ~ #controls label:nth-child(3),
  #slide5:checked ~ #controls label:nth-child(4),
  #slide6:checked ~ #controls label:nth-child(5),
  #slide7:checked ~ #controls label:nth-child(6),
  #slide8:checked ~ #controls label:nth-child(7),
  #slide9:checked ~ #controls label:nth-child(8),
  #slide10:checked ~ #controls label:nth-child(9),
  #slide11:checked ~ #controls label:nth-child(10),
  #slide12:checked ~ #controls label:nth-child(11)
  /* #slide1:checked ~ #controls label:nth-child(12)  */
  {
    left: 4px;
    display: block;
    border: none;
  }
  #example ul.view li.kwo figure picture,
  #example ul.view li.bjk figure picture,
  #example ul.view li.k2 figure picture,
  #example ul.view li.fenerium figure picture,
  #example ul.view li.statistik figure picture{
    max-width: unset;
    max-height: unset;
  }
  figure video {
    max-width:83%;
  }
}
/*--- <= 370 --------------------------------------------------------------------------------*/
@media screen and (max-width: 370px) {
  #example,
  #example.inview {
    background-image: url(../assets/images/hintergrund2022_400.jpg);
    background-position: 50% 40%;
    background-attachment: fixed;
    background-size: 100% auto;
  }
    #example ul.view li figure figcaption {
    width: 90%;
  }
}
/*--- >= 1380 --------------------------------------------------------------------------------*/
@media screen and (min-width: 1380px) {
  #example {
    background-image: url(../assets/images/hintergrund2022.jpg);
    background-position: 50% -1800px;
    background-attachment: fixed;
    background-size: 100% auto;
    width: 100%;
    display: block;
  }
}
/*--- >= 792 --------------------------------------------------------------------------------*/
@media screen and (max-height: 792px) {
  #example {
    padding:5vh 0 18vh 0;
  }
}
</style>
