<template>
  <section id="skills" class="" v-chaperterobserve>
    <div class="bg">
      <div class="skill holder"></div>
      <h3>{{ headline }}</h3>
      <ol class="skills">
        <li class="skillsblock" v-for="skill in skills" v-bind:key="skill" v-chaperterobserve><h4>{{skill.headline}}</h4><p>{{ skill.text }}</p></li>
      </ol>
      <div class="buttonMore" v-on:click="moreOrLessText($event)">mehr...</div>

    </div>
  </section>
</template>

<script>
import paragraphs from '../assets/json/skills.json'
export default {
  name: 'SkillsDisplay',
  data () {
    return {
      headline: 'Kompetenzen'
    }
  },
  methods: {
    scrollTextUp () {
      const element = document.getElementById('skills')
      const exampleTop = element.offsetTop
      // window.setTimeout(() => {
      //   window.scrollTo(0, exampleTop)
      // }, 1000)
      window.scrollTo(0, exampleTop)
    },
    moreOrLessText (event) {
      const toRead = event.target.previousElementSibling
      const moreButton = event.target
      if (toRead.classList.contains('more')) {
        toRead.classList.remove('more')
        moreButton.innerText = 'mehr...'
        this.scrollTextUp()
      } else {
        toRead.classList.add('more')
        moreButton.innerText = 'weniger'
      }
    }
  },
  computed: {
    skills () {
      return paragraphs.skills.map((item) => {
        return item
      })
    }
  }
}
</script>

<style scoped>
  #skills {
    width: 100%;
    height: auto;
    position: relative;
    padding: 6vh 0 16vh 0;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 100vh;
  }
  /*--------------------skills--------------------*/
  #skills {
    background-color:hsl(227.1, 13%, 78.8%);
    background-image:
    radial-gradient(at 40% 20%, hsla(217,9%,13%,0.5) 0px, transparent 50%),
    radial-gradient(at 80% 0%, hsla(217,1%,22%,1) 0px, transparent 50%),
    radial-gradient(at 0% 50%, rgb(69, 69, 84) 0px, transparent 50%),
    radial-gradient(at 80% 50%, rgb(131, 122, 105) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(210,3%,14%,1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(209,11%,20%,1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(240,10%,13%,1) 0px, transparent 50%);
  }
  #skills div.bg ol.skills {
    width: 96vw;
    margin: 0 auto 20px auto;
    text-align: left;
    position: relative;

    display: grid;
    /* list-style-position: inside; */
    list-style: none;
    grid-template-columns: repeat(2,auto);
    grid-template-rows: auto;
    grid-gap: 4em;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }
  #skills ol.skills,
  #skills h3,
  #skills .buttonMore {
    transform: translateX(50vh);
    opacity: 0;
    transition: transform 2s ease-out, opacity 2s ease-out;
  }
  #skills.inview ol.skills,
  #skills.inview h3,
  #skills.inview .buttonMore {
    transform: translateX(0);
    opacity: 1;
    transition: transform 2s ease-out, opacity 2s ease-out;
  }
  #skills ol.skills li{
    counter-increment: li;
    display: list-item;
    list-style-position: inside;
    transform: perspective(2cm) scale(0.9);
    transition: transform .7s ease-out;
    box-shadow: 1vw 1vw 24px rgba(58, 59, 63, 0.9);
  }
  #skills ol.skills li.inview{
    transition: transform .7s ease-out;
  }
  #skills ol.skills li::marker {
    display: list-item;
    text-align: start important!;
    text-transform: none;
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    white-space: pre;
    font-size: 10rem;
    line-height: 1;
    text-indent: 0px important!;
    content: "#" counter(li) " ";
  }
  .holder.skill {
    background: url(../assets/images/kompetenz.png) no-repeat center;
    background-size: 99px 98px;
  }
  #skills .buttonMore {
    display: none;
    margin-top: 20px;
  }
  #skills .skillsblock{
    padding: 5em;
     margin: 0;
    background-color: rgba(58, 59, 63, 0.7);
  }
/*--- < 1380 --------------------------------------------------------------------------------*/
@media screen and (max-width: 1379px) and (min-width: 1060px){
  /* #skills,#skills.inview {
    background-image: url(../assets/images/hintergrund_1380.jpg);
    background-position: 50% -560px;
    background-attachment: fixed;
    background-size: 140% auto;
  } */
}
/*--- < 1060 --------------------------------------------------------------------------------*/
@media screen and (max-width: 1059px) and (min-width: 900px){
  /* #skills,#skills.inview {
    background-image: url(../assets/images/hintergrund_1380.jpg);
    background-position: 50% -560px;
    background-attachment: fixed;
    background-size: 140% auto;
  } */
  #skills .skillsblock{
    padding: 3em;
    background-color: rgba(58, 59, 63, 0.7);
  }
}
/*--- < 900 --------------------------------------------------------------------------------*/
@media screen and (max-width: 899px)and (min-width: 601px) {
  /* #skills,#skills.inview {
    background-image: url(../assets/images/hintergrund_800.jpg);
    background-position: 50% -560px;
    background-attachment: fixed;
    background-size: 140% auto;
  } */
  #skills div.bg ol.skills {
    display: block;
  }
  #skills .skillsblock{
    padding: 3em;
    margin: 1em 0;
    background-color: rgba(58, 59, 63, 0.7);
  }
}
/*--- <= 600 --------------------------------------------------------------------------------*/
@media screen and (max-width: 600px) and (min-width: 460px) {
  /* #skills, #skills.inview {
    background-image: url(../assets/images/hintergrund_400.jpg);
    background-position: 50% 40%;
    background-attachment: fixed;
    background-size: 100% auto;
  } */
  #skills div.bg ol.skills {
    display: block;
  }
  #skills .skillsblock{
    padding: 2em;
    margin: 1em 0;
    background-color: rgba(58, 59, 63, 0.7);
  }
}
/*--- <= 460 --------------------------------------------------------------------------------*/
@media screen and (max-width: 460px) {
  /* #skills, #skills.inview {
    background-image: url(../assets/images/hintergrund_400.jpg);
    background-position: 50% 40%;
    background-attachment: fixed;
    background-size: 100% auto;
  } */
  #skills div.bg ol.skills{
    display: block;
    height: 42vh;
    overflow: hidden;
  }
  #skills .skillsblock{
    padding: 2em 2em;
    margin: 1em 0;
    background-color: rgba(58, 59, 63, 0.7);
  }
  #skills  div.bg ol.skills.more{
    overflow: unset;
    height: auto;
  }
  #skills .buttonMore {
    display: block;
    width: 100px;
    height: 22px;
    text-align: right;
    cursor: pointer;
    position: absolute;
    color:inherit;
    font-size: 1.3rem;
    right: 10%;
  }
}
/*--- <= 370 --------------------------------------------------------------------------------*/
@media screen and (max-width: 370px) {
  /* #skills, #skills.inview {
    background-image: url(../assets/images/hintergrund_400.jpg);
    background-position: 50% 40%;
    background-attachment: fixed;
    background-size: 100% auto;
  } */
}
/*--- >= 1380 --------------------------------------------------------------------------------*/
@media screen and (min-width: 1380px)  {
  /* #skills {
    background-image: url(../assets/images/hintergrund.jpg) ;
    background-position: 50% -560px ;
    background-attachment: fixed;
    background-size: 100% auto;
  } */
}
</style>
