<template>
  <header id="header">
    <nav>
      <ul id="nav" class="verlauf">
        <li><router-link class="topnav" to="/#developer" title="Eva Over"><span class="icon-user">&nbsp;</span></router-link></li>
        <li><router-link class="topnav" to="/#skills" title="Kompetenzen"><span class="icon-graduation-cap">&nbsp;</span></router-link></li>
        <li><router-link class="topnav" to="/#projects" title="Beispiele"><span class="icon-briefcase">&nbsp;</span></router-link></li>
        <li><router-link class="topnav" to="/#contact" title="Kontakt"><span class="icon-email">&nbsp;</span></router-link></li>
      </ul>
    </nav>
  </header>
  <router-view/>
  <footer id="footer" class="verlauf">
    <nav>
      <ul id="footernav" class="clearfix">
        <li><router-link class="subnav" to="/#developer" title="top"><span class="icon-home"></span></router-link></li>
        <li><router-link class ="subnav" to="/impressum#app" title="impressum"><span class="icon-impressum"></span></router-link></li>
        <li><router-link class ="subnav" to="/datenschutz#app" title="datenschutz"><span class="icon-lock-1"></span></router-link></li>
      </ul>
    </nav>
  </footer>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
      site: {
        currentTplSize: '',
        bookmarkedTplSize: ''
      }
    }
  },
  methods: {
    currentPage () {
      //  returns the current path
      return this.$route.path
    },
    getSizeTpl (windowWidth) {
      let size = ''
      if (windowWidth > this.$XLG) {
        size = 'xlg'
      } else if (windowWidth > this.$LG && windowWidth < this.$XLG) {
        size = 'lg'
      } else if (windowWidth < this.$MD && windowWidth > this.$SM) {
        size = 'md'
      } else if (windowWidth <= this.$SM) {
        size = 'sm'
      }
      return size
    },
    setSizeTpl () {
      this.site.currentTplSize = this.getSizeTpl(window.innerWidth)
      // console.log(this.site.currentTplSize)
      return this.site.currentTplSize
    },
    bookmarkTpl () {
      this.site.bookmarkedTplSize = this.site.currentTplSize
      return this.bookmarkedTplSize
    },
    checkTpl () {
      return this.site.bookmarkedTplSize !== this.getSizeTpl(window.innerWidth)
    },
    getCurrentTpl () {
      return this.site.currentTplSize
    },
    resize () {
      if (this.checkTpl() === true) {
        this.setSizeTpl()
        this.bookmarkTpl()
        // this.checkExampleButtons()
        // console.log(this.getCurrentTpl())
      }
    }
  },
  computed: {
  },
  mounted () {
    // console.log('onMounted')
    this.setSizeTpl()
    this.bookmarkTpl()
    window.addEventListener('resize', this.resize)
  },
  updated () {
    // console.log('onUpdated')
  },
  unmounted () {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
<style>@import "./assets/css/over-eva.css";</style>
<style lang="less">
html{
  scroll-behavior: smooth;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #3d3e42;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  display: block;
  min-height: 720px;
  position: relative;
  -webkit-text-size-adjust: none;
  -ms-content-zooming: none;
  font-family: latin_modern_mono_light10Rg, serif;
  font-size: 100%;
  text-align: center;
  hyphens: auto;
  -ms-hyphens: auto;
}
header, #header {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 3000;
  display: block;
}
nav {
  width: 100%;
  display: block;
  height: auto;
}
ul#nav {
  position: relative;
  width: auto;
  margin: 0 auto;
  z-index: 199;
  padding: 0;
}
header ul#nav li {
  display: inline-block;
}
header ul#nav li a {
  display: inline-block;
  width: 48px;
  margin: 4vh 20px;
  height: 48px;
  text-decoration: none;
  background-color: #d2d4bb;
  border-radius: 50%;
  outline: none;
}
header ul#nav li a:hover {
  transform: rotate(-4deg);
  text-decoration: none;
}
header ul#nav li a span, nav a span.icon-menu, nav a span.icon-right-dir {
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  color: #fff;
}
header ul#nav li a span:before {
  font-size: 26px;
  line-height: 45px;
}
/*--- Footer ------------------*/
footer, #footer {
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: absolute;
  bottom:0;
  display: block;
}
footer ul, #footer ul {
  text-align: right;
}

footer ul#footernav li {
  display: inline-block;
}

footer ul#footernav li a {
  display: inline-block;
  width: 48px;
  margin: 8px 20px 0 20px;
  height: 48px;
  text-decoration: none;
  border-radius: 50%;
  background-color: #d2d4bb;
}

footer ul#footernav li a:hover {
  transform: rotate(-4deg);
  text-decoration: none;
}

footer ul#footernav li a span, footer ul#footernav a span.icon-menu, footer ul#footernav a span.icon-right-dir {
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  color: #fff;
}

footer ul#footernav a span.icon-impressum:before {
  font-size: 26px;
  font-weight: bold;
}

footer ul#footernav li a span:before {
  font-size: 26px;
  line-height: 45px;
}

footer p, #footer p {
    float: right;
    width: 160px;
    font-size: 10px;
    line-height: 1.0em;
    padding: 22px 60px 0 0;
}

footer div.fb-share-button {
    position: absolute;
    top: 18px;
    right: 20px;
}
/*    !*--- < 1380 --------------------------------------------------------------------------------*!*/
@media screen and (max-width: 1379px) and (min-width: 1060px){
    body {
        background-image: url(./assets/images/hintergrund2022_1380.jpg);
    }
    p {
        margin-bottom: 0.8rem;
    }
    h3 {
        margin-bottom: 1.8rem;
    }
}
/*    !*--- < 1060 --------------------------------------------------------------------------------*!*/
@media screen and (max-width: 1059px) and (min-width: 900px){

    /*--- General ------------------*/
    figure img,
    figure video {
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 100%;
        margin: auto;
        display: block;
    }
    h3 {
        margin-bottom: 1.6rem;
    }
    figure figcaption {
        width: 71%;
        height: auto;
    }
}
/*--- < 900 --------------------------------------------------------------------------------*/
@media screen and (max-width: 899px)and (min-width: 601px) {
    /*--- General ------------------*/
    h3 {
        margin-bottom: 1.8rem;
    }
}

/*--- <= 600 --------------------------------------------------------------------------------*/
@media screen and (max-width: 600px) and (min-width: 460px) {

/*--- General ------------------*/
    h3 {
        margin-bottom: 30px;
    }
/*--- Footer ------------------*/
    footer ul#footernav li a{
      margin: 8px 6px;
    }
}
/*--- <= 460 --------------------------------------------------------------------------------*/
@media screen and (max-width: 460px) {
  body {
    background-image: url(./assets/images/hintergrund2022_400.jpg);
  }
  h2 {
    font: 24px/1.8 latin_modern_mono_light10Rg;
  }
  h3 {
    margin-bottom: 1.8rem;
  }
  /*--- Header ------------------*/
  header ul#nav li a {
    margin: 4vh 10px;

  }
  /*--- Footer ------------------*/
  footer ul, #footer ul {
    text-align: center;
    padding-left: 0;
  }
}
/*--- <= 370 --------------------------------------------------------------------------------*/
@media screen and (max-width: 370px) {
  /*--- General ------------------*/
  p {
    font-size: 1rem;
    margin-bottom: 0.7rem;
    hyphens: auto;
    -ms-hyphens: auto;
  }
}
/*--- >= 1380 --------------------------------------------------------------------------------*/
@media screen and (min-width: 1380px) {
}
</style>
