import { createApp } from 'vue'
import ChapterObserverDirective from './directives/ChapterObserverDirective'
import LazyLoadDirective from './directives/LazyLoadDirective'
import ImageTag from './components/ImageTag'
import App from './App.vue'
import router from './router'

const app = createApp(App)
app.config.globalProperties.$SM = 460
app.config.globalProperties.$MD = 899
app.config.globalProperties.$LG = 900
app.config.globalProperties.$XLG = 1380
app.config.errorHandler = (error, vm, info) => {
  // handle error
  console.log(error)
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  console.log(info)
  // the error was found in
  console.log(vm)
}
app.directive('chaperterobserve', ChapterObserverDirective)
app.directive('lazyload', LazyLoadDirective)
app.component('ImageTag', ImageTag)
app.use(router).mount('#app')
