export default {
  mounted: (el) => {
    let pictureTags = { }
    const interactImageSettings = {
      root: null,
      rootMargin: '0px 0px 0px 0px'
    }
    function onIntersection (imageEntities, imageObserver) {
      imageEntities.map(entry => {
        if (entry.isIntersecting) {
          imageObserver.unobserve(entry.target)
          writePictureTag(entry.target)
        }
      })
    }
    function writePictureTag (elem) {
      Array.from(elem.children).map(childElem => {
        let attrValue = ''
        if (childElem.nodeName === 'IMG') {
          attrValue = childElem.getAttribute('datasrc')
          childElem.setAttribute('src', attrValue)
          childElem.removeAttribute('datasrc')
        } else {
          attrValue = childElem.getAttribute('datasrcset')
          childElem.setAttribute('srcset', attrValue)
          childElem.removeAttribute('datasrcset')
        }
      })
    }
    if (Array.from(el.children).find(el => el.nodeName === 'PICTURE') !== undefined) {
      pictureTags = Array.from(el.children).find(el => el.nodeName === 'PICTURE')
      if ('IntersectionObserver' in window) {
        const imageObserver = new IntersectionObserver(onIntersection, interactImageSettings)
        imageObserver.observe(pictureTags)
      } else {
        writePictureTag(pictureTags)
      }
    }
  }
}
